import '../../app/vendors/css/forms/select/select2.min.css';
import '../../app/css/plugins/forms/pickers/form-flat-pickr.css';
import '../../app/vendors/css/pickers/flatpickr/flatpickr.min.css';

import flatpickr from "flatpickr";
import Inputmask from 'inputmask';
import feather from "feather-icons";
import select2 from 'select2';
import {
    deleteDialog,
    loadFilters,
    storeFilters,
    swalError,
    swalSuccess,
    swalUnknown,
    toggleLoading
} from "../functions";
import Popover from "bootstrap/js/src/popover";
import _ from "lodash";

function renderLabels_40042c52(option) {
    if (!option.id) {
        return option.text;
    }

    return "<div style='color:#ffffff;background-color:" + $(option.element).data('color') + ";' class='badge badge-pill'> " + option.text + '</div>';
}

function onYearMonthChange_40042c52() {
    let orderStatus = $('#order_status');

    if (orderStatus.val().length === 0) {
        orderStatus.val('0');
        orderStatus.trigger('change');
    }

    $('#myInvoiceTable').data('table').ajax.reload(null, false);
    getInvoiceSummaryTable();
    storeFilters();
}

function onStatusChange_40042c52() {
    let orderStatus = $('#order_status');

    if (orderStatus.val().length === 0) {
        orderStatus.val('0');
        orderStatus.trigger('change');
    }

    $('#myInvoiceTable').data('table').ajax.reload(null, false);
    storeFilters();
}

function onSaveButtonIGS_40042c52(event) {
    let form = $("#group-action-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onGroupActionForm_40042c52(event) {
    let helper = $('#helper');
    event.preventDefault();

    if (!isValid()) {
        return alert(helper.data('invalid-date'));

    }

    toggleLoading('#saveButtonIGS', '#loadingButtonIGS');

    let form = $(this);
    let inputs = form.find("input, select, button, textarea");
    let url = helper.data('group-url');
    let guid = $('#guid').val();
    let progress = $('#action-progress');
    let serializedData = $("#group-action-form, input[name='selected-invoices[]']:checked").serialize();

    inputs.prop("disabled", true);
    progress.removeClass('d-none');

    $('#info ul li span').each(function () {
        $(this).html('<div class="spinner-border spinner-border-sm" role="status">' +
            '<span class="visually-hidden">Loading...</span></div>');
    });

    let notifUrl = new URL($('.notification-options').data('url'));
    notifUrl.searchParams.append('topic', guid);

    let stream = new EventSource(notifUrl.toString());
    stream.onmessage = event => {
        let data = JSON.parse(event.data);
        let orderDiv = $('#' + data.order + '-status');

        if (data.success) {
            orderDiv.html('<span class="text-success"><i data-feather="check"></i> ' + data.message + '</span>');
        } else {
            orderDiv.html('<span class="text-danger"><i data-feather="x"></i> ' + data.message + '</span>');
        }

        progress.children('.progress-bar').prop('style', 'width: ' + data.percent + '%;');
        progress.children('.progress-bar').html(data.percent + '%');

        feather.replace({
            width: 14,
            height: 14
        });
    };

    stream.onopen = () => {
        $.ajax({
            url: url,
            type: "post",
            data: serializedData,
            async: true
        }).done(function (response) {
            if (response.success) {
                form.removeClass("was-validated");
                swalSuccess(response.data);

                progress.addClass('progress-bar-success');
                progress.removeClass('progress-bar-primary');
                progress.children('.progress-bar').removeClass('progress-bar-animated progress-bar-striped');

                if (response.refresh) {
                    $('#myInvoiceTable').data('table').ajax.reload(null, false);
                }
            } else {
                swalError(response.data);

                progress.addClass('progress-bar-danger');
                progress.removeClass('progress-bar-primary');
                progress.children('.progress-bar').removeClass('progress-bar-animated');
            }
        }).always(function () {
            inputs.prop("disabled", false);
            toggleLoading('#saveButtonIGS', '#loadingButtonIGS');
            stream.close();
        }).fail(function (e) {
            swalUnknown(e);
        });
    };
}

export function initInvoiceListPage() {
    require('../datatables');

    window.Inputmask = Inputmask;
    window.flatpickr = flatpickr;
    window.select2 = select2;

    if ($('html').attr('lang') === 'hu') {
        require('select2/dist/js/i18n/hu.js');
        const hu = require("flatpickr/dist/l10n/hu").default.hu;
        flatpickr.localize(hu);
    }

    window.isValid = function () {
        if (!!document.getElementById('invoice-postal-date')) {
            return Inputmask("datetime", {
                inputFormat: "yyyy.mm.dd HH:MM",
                placeholder: "yyyy.mm.dd. hh:ii",
                leapday: "-02-29",
                separator: "."
            }).mask(document.getElementById("invoice-postal-date")).isComplete();
        }

        return true;
    };

    window.getInvoiceSummaryTable = function () {
        $.ajax({
            url: helper.data('summary'),
            type: "post",
            async: true,
            data: {
                "year": year.val(),
                "month": month.val()
            }
        }).done(function (response) {
            totalTable.DataTable().destroy();
            $("#totalTable tbody").html(response.data);
            totalTable.DataTable({
                responsive: true,
                paging: false,
                ordering: false,
                searching: false,
                info: false,
                lengthChange: false
            });

            let popoverTriggerList = [].slice.call(document.querySelectorAll('.has-tooltip'));

            popoverTriggerList.map(function (popoverTriggerEl) {
                return new Popover(popoverTriggerEl, {});
            });
        });
    };

    window.deleteInput = function (id, order_id, mode) {
        if (mode === "d") {
            deleteDialog(id + mode, order_id, helper.data('mode-d-conf'), helper.data('delete'), helper.data('delete-url'), () => {
                $('#myInvoiceTable').data('table').ajax.reload(null, false);
            });
        } else {
            deleteDialog(id + mode, order_id, helper.data('mode-c-conf'), helper.data('cancel'), helper.data('delete-url'), () => {
                $('#myInvoiceTable').data('table').ajax.reload(null, false);
            });
        }
    };

    window.changeAction = function () {
        let text = '';
        let table = $("#myInvoiceTable");
        let action = $("#action").val();
        let progress = $('#action-progress');
        let invoices = $("input[name='selected-invoices[]']:checked");
        let tableRows = table.DataTable().rows()[0];

        $("#group-action-modal .modal-title").html(helper.data('action') + ": " + $("#action option:selected").text());
        $("#group-action-modal .modal-body #errors").html('');

        progress.addClass('d-none progress-bar-primary');
        progress.removeClass('progress-bar-danger progress-bar-success');
        progress.children('.progress-bar').prop('style', 'width: 0;');
        progress.children('.progress-bar').addClass('progress-bar-animated progress-bar-striped');

        if (action) {
            if (invoices.length) {
                text = helper.data('orders') + ' (' + invoices.length + '):<ul>';

                for (let i = 0; i < invoices.length; i++) {
                    for (let j = 0; j < tableRows.length; j++) {
                        if (table.DataTable().row(j).data()[1] === parseInt(invoices.get(i).value)) {
                            let orderId = $(table.DataTable().row(j).data()[2]).children('.order_id_span').text();

                            text += '<li>' + orderId + '<span class="ms-1" id="' + invoices.get(i).value + '-status"></span></li>';
                            break;
                        }
                    }
                }

                text += "</ul><br>";

                $.ajax({
                    url: helper.data('action-form'),
                    type: "post",
                    async: true,
                    data: invoices.serialize() + "&name=" + action
                }).done(function (response) {
                    $("#group-action-modal .modal-body #info").html(text + response.data);
                    $('#guid').val(response.guid);
                    $("#name").val(action);

                    let popoverTriggerList = [].slice.call(document.querySelectorAll('.has-tooltip'));

                    popoverTriggerList.map(function (popoverTriggerEl) {
                        return new Popover(popoverTriggerEl, {});
                    });

                    feather.replace({
                        width: 14,
                        height: 14
                    });

                    if (!!document.getElementById('invoice-postal-date')) {
                        Inputmask("datetime", {
                            inputFormat: "yyyy.mm.dd HH:MM",
                            placeholder: "yyyy.mm.dd. hh:ii",
                            leapday: "-02-29",
                            separator: "."
                        }).mask(document.getElementById("invoice-postal-date"));
                    }

                    $('.datepicker').flatpickr({
                        dateFormat: "Y.m.d.",
                        allowInput: true
                    });
                }).fail(function (e) {
                    swalUnknown(e);
                });
            } else {
                $("#group-action-modal .modal-body #info").html("<span>" + helper.data('no-invoice') + "</span>");
            }
        } else {
            $("#group-action-modal .modal-body #info").html("<span>" + helper.data('no-action') + "</span>");
        }
    };

    let helper = $('#helper');
    let month = $('#month');
    let year = $('#year');
    let canceled = $('#void');
    let draft = $('#draft');
    let orderStatus = $('#order_status');
    let totalTable = $('#totalTable');
    let invoiceTable = $('#myInvoiceTable');

    loadFilters();

    invoiceTable.data('table', invoiceTable.DataTable({
        "responsive": true,
        "language": {
            "url": helper.data('lang')
        },
        "columnDefs": [
            {
                'targets': 'no-sort',
                'orderable': false
            },
            {
                targets: 8,
                responsivePriority: 2,
            },
            {
                targets: 2,
                responsivePriority: 1
            },
            {
                className: 'control',
                orderable: false,
                targets: 0
            },
            {
                targets: 1,
                orderable: false,
                render: function (data) {
                    return (
                        '<div class="form-check"> <input class="form-check-input dt-checkboxes" name="selected-invoices[]" type="checkbox" value="' + data + '" id="checkbox-' +
                        data +
                        '" /><label class="form-check-label" for="checkbox-' +
                        data +
                        '"></label></div>'
                    );
                },
                checkboxes: {
                    selectAllRender:
                        '<div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="checkboxSelectAll" /><label class="form-check-label" for="checkboxSelectAll"></label></div>'
                }
            }
        ],
        "lengthMenu": [[10, 25, 50, 100, 500, 1000, -1], [10, 25, 50, 100, 500, 1000, helper.data('all')]],
        "order": [[3, 'desc'], [2, 'asc']],
        "pageLength": 25,
        "orderCellsTop": false,
        "dom": '<"card-header border-bottom p-1"<"head-label"><"dt-action-buttons text-end"B>><"d-flex justify-content-between align-items-center mx-0 row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>>t<"d-flex justify-content-between mx-0 row"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6"p>>',
        "buttons": [{
            extend: 'colvis',
            columns: ':not(.no-colvis)',
            text: helper.data('colvis'),
        }, {
            extend: 'collection',
            className: 'btn btn-outline-secondary me-2',
            text: feather.icons.share.toSvg({class: 'font-small-4 me-50'}) + helper.data('export'),
            buttons: [
                {
                    extend: 'print',
                    text: feather.icons.printer.toSvg({class: 'font-small-4 me-50'}) + helper.data('print'),
                    className: 'dropdown-item',
                    exportOptions: {
                        columns: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
                        modifier: {
                            order: 'current',
                            page: 'all',
                            selected: null
                        }
                    },
                    orientation: 'landscape',
                    customize: function (win) {
                        let css = '@page { size: landscape; }',
                            head = win.document.head || win.document.getElementsByTagName('head')[0],
                            style = win.document.createElement('style');
                        style.type = 'text/css';
                        style.media = 'print';
                        if (style.styleSheet) {
                            style.styleSheet.cssText = css;
                        } else {
                            style.appendChild(win.document.createTextNode(css));
                        }
                        head.appendChild(style);
                    }
                },
                {
                    extend: 'csvHtml5',
                    text: feather.icons['file-text'].toSvg({class: 'font-small-4 me-50'}) + 'Csv',
                    className: 'dropdown-item',
                    exportOptions: {
                        columns: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
                        modifier: {
                            order: 'current',
                            page: 'all',
                            selected: null
                        }
                    }
                },
                {
                    extend: 'excelHtml5',
                    text: feather.icons.file.toSvg({class: 'font-small-4 me-50'}) + 'Excel',
                    className: 'dropdown-item',
                    exportOptions: {
                        columns: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
                        modifier: {
                            order: 'current',
                            page: 'all',
                            selected: null
                        }
                    }
                },
                {
                    extend: 'pdfHtml5',
                    text: feather.icons.clipboard.toSvg({class: 'font-small-4 me-50'}) + 'Pdf',
                    className: 'dropdown-item',
                    exportOptions: {
                        columns: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
                        modifier: {
                            order: 'current',
                            page: 'all',
                            selected: null
                        }
                    }
                },
                {
                    extend: 'copyHtml5',
                    text: feather.icons.copy.toSvg({class: 'font-small-4 me-50'}) + helper.data('copy'),
                    className: 'dropdown-item',
                    exportOptions: {
                        columns: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
                        modifier: {
                            order: 'current',
                            page: 'all',
                            selected: null
                        }
                    }
                }
            ],
            init: function (api, node) {
                $(node).removeClass('btn-secondary');
                $(node).parent().removeClass('btn-group');
                setTimeout(function () {
                    $(node).closest('.dt-buttons').removeClass('btn-group').addClass('d-inline-flex');
                }, 50);
            }
        }, {
            text: '<i data-feather="refresh-cw"></i> ' + helper.data('refresh'),
            name: 'refresh',
            className: 'refresh-table btn btn-outline-secondary'
        }
        ],
        "colReorder": true,
        "stateSave": true,
        "paging": true,
        "orderClasses": false,
        "deferRender": true,
        "processing": true,
        "serverSide": true,
        "searchDelay": 750,
        "ajax": {
            "url": helper.data('url'),
            "async": true,
            "data": function (d) {
                d.reset = false;
                d.year = year.val();
                d.month = month.val();
                d.void = canceled.val();
                d.draft = draft.val();
                d.order_status = orderStatus.val();
            },
            "cache": false,
            "method": 'GET',
            "pages": 2
        }
    }).on('draw.dt', function () {
        let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));

        popoverTriggerList.map(function (popoverTriggerEl) {
            return new Popover(popoverTriggerEl, {});
        });

        feather.replace({
            width: 24,
            height: 24
        });

        $('.dataTables_filter input').removeClass('form-control-sm').addClass('form-control');
        $('.dataTables_length select').removeClass('form-select-sm').removeClass('form-control-sm').addClass('form-select');
    }).on('responsive-display', function () {
        feather.replace({
            width: 14,
            height: 14
        });

        let popoverTriggerList = [].slice.call(document.querySelectorAll('.has-tooltip'));

        popoverTriggerList.map(function (popoverTriggerEl) {
            return new Popover(popoverTriggerEl, {});
        });
    }).on('preInit', function () {
        //$('.buttons-colvis').addClass('btn btn-outline-secondary me-1');
    }).on('buttons-action', function () {
        $('.buttons-columnVisibility').addClass('dropdown-item');
    }));

    let search = $('input[type=search].form-control');

    search.on("keyup", _.debounce(function () {
        table.search(search.val()).draw();
    }, 500));

    orderStatus.wrap("<div class='position-relative mb-1'></div>").select2({
        placeholder: helper.data('select-option'),
        dropdownParent: orderStatus.parent(),
        minimumResultsForSearch: -1,
        templateResult: renderLabels_40042c52,
        templateSelection: renderLabels_40042c52,
        escapeMarkup: function (es) {
            return es;
        }
    });

    getInvoiceSummaryTable();

    $(document).on("click", ".refresh-table", onStatusChange_40042c52);
    month.on('change', onYearMonthChange_40042c52);
    year.on('change', onYearMonthChange_40042c52);
    canceled.on('change', onStatusChange_40042c52);
    draft.on('change', onStatusChange_40042c52);
    orderStatus.on('change', onStatusChange_40042c52);
    $("#saveButtonIGS").on("click", onSaveButtonIGS_40042c52);
    $("#group-action-form").on('submit', onGroupActionForm_40042c52);
}

export function unloadInvoiceListPage() {
    delete window.Inputmask;
    delete window.flatpickr;
    delete window.select2;
    delete window.isValid;
    delete window.getInvoiceSummaryTable;
    delete window.deleteInput;
    delete window.changeAction;

    let orderStatus = $('#order_status');

    $('#myInvoiceTable').DataTable().destroy();
    $('.flatpickr-calendar').remove();
    orderStatus.select2('destroy');

    $(document).off("click", ".refresh-table", onStatusChange_40042c52);
    orderStatus.off('change', onStatusChange_40042c52);
    $('#month').off('change', onYearMonthChange_40042c52);
    $('#year').off('change', onYearMonthChange_40042c52);
    $('#void').off('change', onStatusChange_40042c52);
    $('#draft').off('change', onStatusChange_40042c52);
    $("#saveButtonIGS").off("click", onSaveButtonIGS_40042c52);
    $("#group-action-form").off('submit', onGroupActionForm_40042c52);
}
